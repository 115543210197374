import React from 'react'

function FooterInfo({text,icon,language}) {
  return (
    <div className={`flex  items-center justify-items-center m-4 `}>
        <img className={`${language=='en'?'mr-4':'ml-4'} `} src={icon} alt="icon" />
         <h1 className='text-white text-lg font-semibold ' >{text}</h1>
    </div>
  )
}

export default FooterInfo